/* eslint-disable no-unused-vars */
/* eslint-disable capitalized-comments */
/* eslint-disable react/prop-types */
import React, { useState, Fragment } from "react";
import useUserContext from "../../../hooks/useUserContext";
import { CgFontSpacing } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { PieChart, Pie, Cell, Label, Tooltip } from "recharts";
import Modal from "../../../components/modal/Modal";
import { MAGIC_NUMBERS } from "../../../constant";
import notify from "../../../services/toast";
import sendCpInvite from "./sendCpInvite";
import CommunityPartnerForm from "../../community_partner/CommunityPartnerForm";

export const Total = ({ marketData, type, dashstats }) => {
	const { userState } = useUserContext();
	const award = dashstats.annualAward;
	const giveAward = dashstats.annualGiveAward;
	const boostAward = dashstats.annualBoostAward;
	const cpAward = dashstats.annualCPAward;
	const navigate = useNavigate();
	const renewal = dashstats.totalRenewals;
	const nonRenewal = dashstats.totalNonRenewals;
	const giveRenewal = dashstats.totalGiveRenewals;
	const giveNonRenewal = dashstats.totalGiveNonRenewals;
	const boostRenewal = dashstats.totalBoostRenewals;
	const boostNonRenewal = dashstats.totalBoostNonRenewals;
	const [inviteCP, setInviteCP] = useState(false);
	const [cpEmail, setCPEmail] = useState("");
	const [showCPForm, setShowCPForm] = useState(false);
	const [isSending, setIsSending] = useState(false);
	//Pie graph
	const data = [
		{ name: "Renewing Scholars", value: renewal, color: "#ffbb28" },
		{ name: "New Scholars", value: nonRenewal, color: "#00C49F" },
	];

	const dataGive = [
		{ name: "Renewing Scholars", value: giveRenewal, color: "#ffbb28" },
		{ name: "New Scholars", value: giveNonRenewal, color: "#00C49F" },
	];
	const dataBoost = [
		{ name: "Renewing Scholars", value: boostRenewal, color: "#ffbb28" },
		{ name: "New Scholars", value: boostNonRenewal, color: "#00C49F" },
	];

	function CustomLabel({ viewBox, value1, value2 }) {
		const { cx, cy } = viewBox;
		return (
			<svg
				// width="500"
				// height="200"
				className="recharts-text recharts-label"
				textAnchor="middle"
				dominantBaseline="central"
			>
				<text x={cx} y={cy} fill="#3d405c">
					<tspan x={cx} dy="0em" alignmentBaseline="middle" fontSize="14">
						{`${value1} Renewing `}
					</tspan>
					<tspan x={cx} dy="1em" fontSize="14">
						{`${value2} New`}
					</tspan>
				</text>
			</svg>
			//  <text x={cx} y={cy} fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
			//     <tspan alignmentBaseline="middle" fontSize="26">{value1}</tspan>
			//     <tspan fontSize="14">{value2}</tspan>
			//  </text>
		);
	}

	const onSubmit = async (event) => {
		event.preventDefault();
		const emailAddress = { email: cpEmail };
		setIsSending(true); // Disable the button while sending
		try {
			const response = await sendCpInvite(emailAddress);
			setIsSending(false); // Re-enable the button after sending

			if (response.status === "success") {
				notify("Invite sent successfully!", "success");
			} else {
				let displayMessage = "";
				if (Array.isArray(response.message)) {
					displayMessage = response.message[0].message;
				} else {
					displayMessage = response.message;
					if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
						displayMessage =
							"An error has occurred. Please try again later. If this error persists, please contact support.";
					}
				}
				notify(displayMessage, "error");
			}
		} catch (error) {
			setIsSending(false); // Re-enable the button after catching an error
			notify("Unable to send Invite. Please try again later.", "error");
		}
	};
	if (!type) {
		return (
			<>
				<div className="grid grid-cols-6">
					<div className="mt-10 col-span-4">
						<div className="widgets gap-x-4 flex-1 flex">
							<div>
								<div className="widget h-36 bg-white rounded-lg shadow-md shadow-grey-600 flex flex-1 p-2 justify-between">
									<div className="left flex flex-col ">
										<span className="title text-4xl font-bold mb-3">
											$
											{award?.[0]?.annualAward
												? award[0].annualAward.toLocaleString()
												: 0}
										</span>

										<span className="counter text-gray-500 text-xl">
											Scholarship Dollars Awarded
										</span>
									</div>
								</div>

								<div className="widget h-36 mt-4 bg-white rounded-lg shadow-md shadow-grey-600 flex flex-1 p-2 justify-between">
									<div className="left flex flex-col ">
										<span className="title text-4xl font-bold mb-3">
											{dashstats.totalApprovedApplications}
										</span>
										<span className="counter text-gray-500 text-xl">
											Active Scholars
										</span>
									</div>
								</div>
							</div>

							<div className="widget h-36 bg-white rounded-lg shadow-md shadow-grey-600 flex flex-1 p-2 justify-between">
								<div className="left flex flex-col ">
									<span className="title text-4xl font-bold mb-3">
										{dashstats.totalPendingApplications}
									</span>
									<span className="counter text-gray-500 text-xl">
										Pending Scholars{" "}
									</span>
								</div>
							</div>

							<div className="widget h-36 bg-white rounded-lg shadow-md shadow-grey-600 flex flex-1 p-2 justify-between">
								<div className="left flex flex-col ">
									<span className="title text-4xl font-bold mb-3">
										{dashstats.totalDeniedApplications}
									</span>
									<span className="counter text-gray-500 text-xl">
										Denied Scholars{" "}
									</span>
								</div>
							</div>
						</div>
					</div>
					<div className="col-span-2">
						<div className="m-7">
							<h1 className="ml-5 text-2xl semi-bold">
								Renewal VS. New Scholars
							</h1>
							<PieChart width={300} height={300}>
								<Pie
									data={data}
									cx={100}
									cy={100}
									innerRadius={60}
									outerRadius={100}
									fill="#8884d8"
									dataKey="value"
									paddingAngle={5}
								>
									<Label
										content={
											<CustomLabel value1={renewal} value2={nonRenewal} />
										}
									></Label>
									{data.map((entry, index) => (
										<Cell key={`cell-${index}`} fill={entry.color} />
									))}
								</Pie>
								<Tooltip />
							</PieChart>
						</div>
					</div>
				</div>
				<div className="mt-8 flow-root">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							<div className="overflow-hidden bg-white shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
								<table className="min-w-full divide-y divide-gray-300">
									<thead className="mb-3  shadow ring-1 ring-black ring-opacity-5">
										<tr>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold"
											>
												City
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold"
											>
												Total
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold"
											>
												Pending
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold"
											>
												Approved
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold "
											>
												Denied
											</th>
										</tr>
									</thead>

									<tbody className="divide-y divide-gray-200 bg-white">
										<tr
											className="cursor-pointer"
											onClick={() => {
												navigate("/dashboard/locations/charlotte");
											}}
										></tr>
										<tr
											className="cursor-pointer"
											onClick={() => {
												navigate("/dashboard/locations/atlanta");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Atlanta
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalAtlanta}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalPendingAtlanta}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalApprovedAtlanta}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalDeniedAtlanta}
											</td>
										</tr>
										<tr
											className="cursor-pointer"
											onClick={() => {
												navigate("/dashboard/locations/charlotte");
											}}
										>
											<td className=" whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Charlotte
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalCharlotte}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalPendingCharlotte}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalApprovedCharlotte}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalDeniedCharlotte}
											</td>
										</tr>
										<tr
											className="cursor-pointer"
											onClick={() => {
												navigate("/dashboard/locations/cincinnati");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Cincinnati
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalCincinnati}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalPendingCincinnati}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalApprovedCincinnati}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalDeniedCincinnati}
											</td>
										</tr>
										<tr
											className="cursor-pointer"
											onClick={() => {
												navigate("/dashboard/locations/cleveland");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Cleveland
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalCleveland}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalPendingCleveland}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalApprovedCleveland}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalDeniedCleveland}
											</td>
										</tr>
										<tr
											className="cursor-pointer"
											onClick={() => {
												navigate("/dashboard/locations/columbus");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Columbus
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalColumbus}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalPendingColumbus}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalApprovedColumbus}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalDeniedColumbus}
											</td>
										</tr>

										<tr
											className="cursor-pointer"
											onClick={() => {
												navigate("/dashboard/locations/indianapolis");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Indianapolis
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalIndianapolis}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalPendingIndianapolis}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalApprovedIndianapolis}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalDeniedIndianapolis}
											</td>
										</tr>
										<tr
											className="cursor-pointer"
											onClick={() => {
												navigate("/dashboard/locations/pittsburgh");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Pittsburgh
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalPittsburgh}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalPendingPittsburgh}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalApprovedPittsburgh}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalDeniedPittsburgh}
											</td>
										</tr>
										<tr
											className="cursor-pointer"
											onClick={() => {
												navigate("/dashboard/locations/springfield");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Springfield
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalSpringfield}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalPendingSpringfield}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalApprovedSpringfield}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalDeniedSpringfield}
											</td>
										</tr>
										<tr
											className="cursor-pointer"
											onClick={() => {
												navigate("/dashboard/locations/tampa_bay");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Tampa Bay
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalTampa}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalPendingTampa}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalApprovedTampa}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalDeniedTampa}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	} else if (type === "give") {
		return (
			<>
				<div className="grid grid-cols-6">
					<div className="mt-10 col-span-4">
						<div className="widgets gap-x-4 flex-1 flex">
							<div>
								<div className="widget h-36 bg-white rounded-lg shadow-md shadow-grey-600 flex flex-1 p-2 justify-between">
									<div className="left flex flex-col ">
										<span className="title text-4xl font-bold mb-3">
											$
											{giveAward?.[0]?.annualAward
												? giveAward[0].annualAward.toLocaleString()
												: 0}
										</span>
										<span className="counter text-gray-500 text-xl">
											Scholarship Dollars Awarded
										</span>
									</div>
								</div>

								<div className="widget h-36 mt-4 bg-white rounded-lg shadow-md shadow-grey-600 flex flex-1 p-2 justify-between">
									<div className="left flex flex-col ">
										<span className="title text-4xl font-bold mb-3">
											{dashstats.totalApprovedGiveApplications}
										</span>
										<span className="counter text-gray-500 text-xl">
											Active Scholars{" "}
										</span>
									</div>
								</div>
							</div>

							<div className="widget h-36 bg-white rounded-lg shadow-md shadow-grey-600 flex flex-1 p-2 justify-between">
								<div className="left flex flex-col ">
									<span className="title text-4xl font-bold mb-3">
										{dashstats.totalPendingGiveApplications}
									</span>
									<span className="counter text-gray-500 text-xl">
										Pending Scholars{" "}
									</span>
								</div>
							</div>

							<div className="widget h-36 bg-white rounded-lg shadow-md shadow-grey-600 flex flex-1 p-2 justify-between">
								<div className="left flex flex-col ">
									<span className="title text-4xl font-bold mb-3">
										{dashstats.totalDeniedGiveApplications}
									</span>
									<span className="counter text-gray-500 text-xl">
										Denied Scholars{" "}
									</span>
								</div>
							</div>
						</div>
					</div>
					<div className="col-span-2">
						<div className="m-5">
							<h1 className="ml-5 text-2xl semi-bold">
								Renewal VS. New Scholars
							</h1>
							<PieChart width={300} height={300}>
								<Pie
									data={dataGive}
									cx={100}
									cy={100}
									innerRadius={60}
									outerRadius={100}
									fill="#8884d8"
									dataKey="value"
									paddingAngle={5}
								>
									<Label
										content={
											<CustomLabel value1={giveRenewal} value2={boostRenewal} />
										}
									></Label>
									{data.map((entry, index) => (
										<Cell key={`cell-${index}`} fill={entry.color} />
									))}
								</Pie>
								<Tooltip />
							</PieChart>
						</div>
					</div>

					{/* <div style={{height: 200}}>
			   <PieChart/>
			 </div> */}
				</div>
				<div className="mt-8 flow-root">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							<div className="overflow-hidden bg-white shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
								<table className="min-w-full divide-y divide-gray-300">
									<thead className="mb-3  shadow ring-1 ring-black ring-opacity-5">
										<tr>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold"
											>
												City
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold"
											>
												Total
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold"
											>
												Pending
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold"
											>
												Approved
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold "
											>
												Denied
											</th>
										</tr>
									</thead>

									<tbody className="divide-y divide-gray-200 bg-white">
										<tr
											onClick={() => {
												navigate("/dashboard/locations/charlotte");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Charlotte
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveCharlotte}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGivePendingCharlotte}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveApprovedCharlotte}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveDeniedCharlotte}
											</td>
										</tr>
										<tr
											onClick={() => {
												navigate("/dashboard/locations/cincinnati");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Cincinnati
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveCincinnati}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGivePendingCincinnati}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveApprovedCincinnati}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveDeniedCincinnati}
											</td>
										</tr>
										<tr
											onClick={() => {
												navigate("/dashboard/locations/cleveland");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Cleveland
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveCleveland}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGivePendingCleveland}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveApprovedCleveland}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveDeniedCleveland}
											</td>
										</tr>
										<tr
											onClick={() => {
												navigate("/dashboard/locations/columbus");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Columbus
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveColumbus}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGivePendingColumbus}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveApprovedColumbus}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveDeniedColumbus}
											</td>
										</tr>
										<tr
											onClick={() => {
												navigate("/dashboard/locations/indianapolis");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Indianapolis
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveIndianapolis}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGivePendingIndianapolis}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveApprovedIndianapolis}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveDeniedIndianapolis}
											</td>
										</tr>
										<tr
											onClick={() => {
												navigate("/dashboard/locations/pittsburgh");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Pittsburgh
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGivePittsburgh}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGivePendingPittsburgh}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveApprovedPittsburgh}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveDeniedPittsburgh}
											</td>
										</tr>
										<tr
											onClick={() => {
												navigate("/dashboard/locations/tampa_bay");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Tampa Bay
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveTampa}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGivePendingTampa}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveApprovedTampa}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveDeniedTampa}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	} else if (type === "boost") {
		return (
			<>
				<div className="grid grid-cols-6">
					<div className="mt-10 col-span-4">
						<div className="widgets gap-x-4 flex-1 flex">
							<div>
								<div className="widget h-36 bg-white rounded-lg shadow-md shadow-grey-600 flex flex-1 p-2 justify-between">
									<div className="left flex flex-col ">
										<span className="title text-4xl font-bold mb-3">
											$
											{boostAward?.[0]?.annualAward
												? boostAward[0].annualAward.toLocaleString()
												: 0}
										</span>
										<span className="counter text-gray-500 text-xl">
											Scholarship Dollars Awarded
										</span>
									</div>
								</div>

								<div className="widget h-36 mt-4 bg-white rounded-lg shadow-md shadow-grey-600 flex flex-1 p-2 justify-between">
									<div className="left flex flex-col ">
										<span className="title text-4xl font-bold mb-3">
											{dashstats.totalApprovedBoostApplications}
										</span>
										<span className="counter text-gray-500 text-xl">
											Active Scholars{" "}
										</span>
									</div>
								</div>
							</div>

							<div className="widget h-36 bg-white rounded-lg shadow-md shadow-grey-600 flex flex-1 p-2 justify-between">
								<div className="left flex flex-col ">
									<span className="title text-4xl font-bold mb-3">
										{dashstats.totalPendingBoostApplications}
									</span>
									<span className="counter text-gray-500 text-xl">
										Pending Scholars{" "}
									</span>
								</div>
							</div>

							<div className="widget h-36 bg-white rounded-lg shadow-md shadow-grey-600 flex flex-1 p-2 justify-between">
								<div className="left flex flex-col ">
									<span className="title text-4xl font-bold mb-3">
										{dashstats.totalDeniedBoostApplications}
									</span>
									<span className="counter text-gray-500 text-xl">
										Denied Scholars{" "}
									</span>
								</div>
							</div>
						</div>
					</div>
					<div className="col-span-2">
						<div className="m-5">
							<h1 className="ml-5 text-2xl semi-bold">
								Renewal VS. New Scholars
							</h1>
							<PieChart width={300} height={300}>
								<Pie
									data={dataBoost}
									cx={100}
									cy={100}
									innerRadius={60}
									outerRadius={100}
									fill="#8884d8"
									dataKey="value"
									paddingAngle={5}
								>
									<Label
										content={
											<CustomLabel
												value1={boostRenewal}
												value2={boostNonRenewal}
											/>
										}
									></Label>
									{data.map((entry, index) => (
										<Cell key={`cell-${index}`} fill={entry.color} />
									))}
								</Pie>
								<Tooltip />
							</PieChart>
						</div>
					</div>
				</div>
				<div className="mt-8 flow-root">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							<div className="overflow-hidden bg-white shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
								<table className="min-w-full divide-y divide-gray-300">
									<thead className="mb-3  shadow ring-1 ring-black ring-opacity-5">
										<tr>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold"
											>
												City
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold"
											>
												Total
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold"
											>
												Pending
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold"
											>
												Approved
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold "
											>
												Denied
											</th>
										</tr>
									</thead>

									<tbody className="divide-y divide-gray-200 bg-white">
										<tr
											onClick={() => {
												navigate("/dashboard/locations/charlotte");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Charlotte
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostCharlotte}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostPendingCharlotte}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostApprovedCharlotte}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostDeniedCharlotte}
											</td>
										</tr>
										<tr
											onClick={() => {
												navigate("/dashboard/locations/cincinnati");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Cincinnati
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostCincinnati}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostPendingCincinnati}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostApprovedCincinnati}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostDeniedCincinnati}
											</td>
										</tr>
										<tr
											onClick={() => {
												navigate("/dashboard/locations/cleveland");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Cleveland
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostCleveland}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostPendingCleveland}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostApprovedCleveland}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostDeniedCleveland}
											</td>
										</tr>
										<tr
											onClick={() => {
												navigate("/dashboard/locations/columbus");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Columbus
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostColumbus}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostPendingColumbus}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostApprovedColumbus}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostDeniedColumbus}
											</td>
										</tr>
										<tr
											onClick={() => {
												navigate("/dashboard/locations/indianapolis");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Indianapolis
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostIndianapolis}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostPendingIndianapolis}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostApprovedIndianapolis}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveDeniedIndianapolis}
											</td>
										</tr>
										<tr
											onClick={() => {
												navigate("/dashboard/locations/pittsburgh");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Pittsburgh
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostPittsburgh}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostPendingPittsburgh}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostApprovedPittsburgh}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostDeniedPittsburgh}
											</td>
										</tr>
										<tr
											onClick={() => {
												navigate("/dashboard/locations/tampa_bay");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Tampa Bay
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostTampa}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostPendingTampa}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostApprovedTampa}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostDeniedTampa}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	} else if (type === "Community Partner") {
		return (
			<>
				<div className="grid grid-cols-6">
					<div className="mt-10 col-span-4">
						<div className="widgets gap-x-4 flex-1 flex">
							<div>
								<div className="widget h-36 bg-white rounded-lg shadow-md shadow-grey-600 flex flex-1 p-2 justify-between">
									<div className="left flex flex-col ">
										<span className="title text-4xl font-bold mb-3">
											$
											{cpAward?.[0]?.annualAward
												? cpAward[0].annualAward.toLocaleString()
												: 0}
										</span>
										<span className="counter text-gray-500 text-xl">
											Scholarship Dollars Awarded
										</span>
									</div>
								</div>

								<div className="widget h-36 mt-4 bg-white rounded-lg shadow-md shadow-grey-600 flex flex-1 p-2 justify-between">
									<div className="left flex flex-col ">
										<span className="title text-4xl font-bold mb-3">
											{dashstats.totalApprovedCPApplications}
										</span>
										<span className="counter text-gray-500 text-xl">
											Active Scholars{" "}
										</span>
									</div>
								</div>
							</div>

							<div className="widget h-36 bg-white rounded-lg shadow-md shadow-grey-600 flex flex-1 p-2 justify-between">
								<div className="left flex flex-col ">
									<span className="title text-4xl font-bold mb-3">
										{dashstats.totalPendingCPApplications}
									</span>
									<span className="counter text-gray-500 text-xl">
										Pending Scholars{" "}
									</span>
								</div>
							</div>

							<div className="widget h-36 bg-white rounded-lg shadow-md shadow-grey-600 flex flex-1 p-2 justify-between">
								<div className="left flex flex-col ">
									<span className="title text-4xl font-bold mb-3">
										{dashstats.totalDeniedCPApplications}
									</span>
									<span className="counter text-gray-500 text-xl">
										Denied Scholars{" "}
									</span>
								</div>
							</div>
						</div>
					</div>
					<div className="col-span-2">
						<div className="m-5 mt-10">
							<h1 className="font-bold">Add New Community Partner</h1>
							<div className="w-full max-w-md lg:col-span-5 lg:pt-2">
								<div className="flex gap-x-4">
									<button
										onClick={() => {
											setInviteCP(true);
										}}
										className="flex-none rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
									>
										Actions
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="mt-8 flow-root">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							<div className="overflow-hidden bg-white shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
								<table className="min-w-full divide-y divide-gray-300">
									<thead className="mb-3  shadow ring-1 ring-black ring-opacity-5">
										<tr>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold"
											>
												City
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold"
											>
												Total
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold"
											>
												Pending
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold"
											>
												Approved
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold "
											>
												Denied
											</th>
										</tr>
									</thead>

									<tbody className="divide-y divide-gray-200 bg-white">
										<tr
											onClick={() => {
												navigate("/dashboard/locations/charlotte");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Charlotte
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalCPCharlotte}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalCPPendingCharlotte}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalCPApprovedCharlotte}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalCPDeniedCharlotte}
											</td>
										</tr>
										<tr
											onClick={() => {
												navigate("/dashboard/locations/cincinnati");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Cincinnati
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalCPCincinnati}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalCPPendingCincinnati}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalCPApprovedCincinnati}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalCPDeniedCincinnati}
											</td>
										</tr>
										<tr
											onClick={() => {
												navigate("/dashboard/locations/cleveland");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Cleveland
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostCleveland}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostPendingCleveland}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostApprovedCleveland}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostDeniedCleveland}
											</td>
										</tr>
										<tr
											onClick={() => {
												navigate("/dashboard/locations/columbus");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Columbus
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostColumbus}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostPendingColumbus}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostApprovedColumbus}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostDeniedColumbus}
											</td>
										</tr>
										<tr
											onClick={() => {
												navigate("/dashboard/locations/indianapolis");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Indianapolis
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostIndianapolis}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostPendingIndianapolis}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostApprovedIndianapolis}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveDeniedIndianapolis}
											</td>
										</tr>
										<tr
											onClick={() => {
												navigate("/dashboard/locations/pittsburgh");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Pittsburgh
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostPittsburgh}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostPendingPittsburgh}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostApprovedPittsburgh}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostDeniedPittsburgh}
											</td>
										</tr>
										<tr
											onClick={() => {
												navigate("/dashboard/locations/tampa_bay");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Tampa Bay
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalCPTampa}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalCPPendingTampa}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalCPApprovedTampa}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalCPDeniedTampa}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				{inviteCP && (
					<Modal>
						<div className="fixed z-10 inset-0 overflow-y-auto">
							<div className="flex items-center justify-center min-h-screen p-4">
								<div className="relative inline-block align-bottom bg-white rounded-lg shadow-xl transition-transform transform sm:max-w-xl md:max-w-2xl lg:max-w-3xl w-full p-8 space-y-6">
									{!showCPForm && (
										<>
											<button
												className="absolute top-4 left-4 text-gray-600 hover:text-gray-800 transition duration-300"
												onClick={() => setInviteCP(false)}
											>
												<span className="material-icons-outlined text-2xl">arrow_back_ios</span>
											</button>
											<h1 className="text-2xl font-bold text-center">Invite a Community Partner</h1>
											<p className="text-gray-700 text-center mb-4">
												Please enter the email of the Community Partner you would like to send an invite to.
												After they receive the email, they will be prompted to submit their information.
											</p>
											<form onSubmit={onSubmit} noValidate className="w-full">
												<div className="flex flex-col sm:flex-row gap-4">
													<input
														id="email-address"
														name="email"
														type="email"
														autoComplete="email"
														required
														className="flex-grow rounded-md border-gray-300 shadow-sm focus:ring-2 focus:ring-indigo-600 placeholder:text-gray-400 sm:py-2 sm:px-3"
														placeholder="Enter email"
														onChange={(event) => setCPEmail(event.target.value)}
													/>
													<button
														type="submit"
														className="flex-none rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-md hover:bg-indigo-500 transition duration-300"
														disabled={isSending}
													>
														{isSending ? "Sending..." : "Send"}
													</button>
												</div>
											</form>

											<div className="mt-6 text-center">
												<h2 className="text-lg font-bold">Create Profile</h2>
												<p className="text-gray-600 mb-4">
													Fill in the Community Partner's details.
												</p>
												<button
													onClick={() => setShowCPForm(true)}
													className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-md hover:bg-indigo-500 transition duration-300"
												>
													Community Partner Form{" "}
													<span className="material-icons-outlined text-xs font-bold">arrow_forward_ios</span>
												</button>
											</div>
										</>
									)}
									{showCPForm && (
										<div className="py-6 px-4 bg-gray-100 rounded-lg shadow-lg relative">
											<button
												className="absolute top-4 left-4 flex items-center text-gray-600 hover:text-gray-800 transition duration-300"
												onClick={() => setShowCPForm(false)}
												aria-label="Go back"
											>
												<span className="material-icons-outlined text-4xl mr-2">arrow_back</span>
												Go Back
											</button>
											<CommunityPartnerForm />
										</div>
									)}

								</div>
							</div>
						</div>
					</Modal>
				)}

			</>
		);
	}
};
